@import "../../../common/styles/sass/variables.scss";
@import "../../../common/styles/sass/mixins.scss";

.mapBlock{
    background-color:#ebeced;
}
.container{
    padding-bottom: 30px ;
    @include container();
    @include flex(column, flex-start, center, wrap);
    .listBox{
        border-bottom: solid 4px #0eb0ee;
        width: 200px;
        height: 600px;
        .text{
            color: #30355d;
        }
    }
}
