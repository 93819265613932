@import "../../../common/styles/sass/variables.scss";
@import "../../../common/styles/sass/mixins.scss";

@font-face {
  font-family: JetBrainsMono ;
  src: url('../../../common/fonts/JetBrainsMono-Regular.ttf');
}
@font-face {
  font-family: Manrope ;
  src: url('../../../common/fonts/Manrope-Regular.ttf');
}

.btn{
  font-family: JetBrainsMono;
  font-weight: 700;
  line-height: 1.9167;
  border: solid 1px  #0eb0ee;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  background-color: #30355d;
  color: #0eb0ee;
  font-size: 12px;
  cursor: pointer;
  border-radius: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: background-color 0.5s ease 0s;
  position: relative;
  top: 0;
  &:hover{
    color: white;
    background-color: #0eb0ee;
  }
  &:active{
    top: 3px;

  }
}
