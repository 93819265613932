@font-face {
  font-family: JetBrainsMono ;
  src: url('../../common/fonts/JetBrainsMono-Regular.ttf');
}
@font-face {
  font-family: Manrope ;
  src: url('../../common/fonts/Manrope-Regular.ttf');
}

.mainBlock {
  position: relative;
  .imgBlock {
    position: relative;
    background-color: #0eb0ee;
    .mainImg {
      bottom: -3px;
      width: 1750px ;
      height: 720px ;
      position: relative;
    }
    .text {
      position: absolute;
      top: 200px;
      left: 300px;
      color: white;
      font-family: JetBrainsMono;
      z-index: 9;
      opacity: 1;
      text-align: inherit;
      line-height: 85px;
      border-width: 0;
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 88px;
      min-height: 0;
      max-height: none;
    }
  }
}




