.atBlock{
  .listBox{
    margin: -10px 200px 50px 33px;
    height: 370px;
    width: 260px;
    background-color: #fff;
    border-bottom: solid 4px #0eb0ee;
  }
}
.decriptionBlock{
  opacity: 0.8;
  margin: -170px 0 0 -60px;
  position: absolute;
  background-color: grey;
  width: 300px;
  height: 300px;
  padding: 30px 30px;
  border-bottom: solid 4px #0eb0ee;
  .decription{
    position: relative;
    color: white;

  }
}

