@import "../../../common/styles/sass/variables";
@import "../../../common/styles/sass/mixins";

.contactsBlock {
  .title{
    text-align: center;
    @include text($titleFontFamily,  2.2rem, 700, 1.0455  );
    color: #fff;
    padding-top: 30px;
  }
  background-color: #30355d;
  .container {
    @include container();
    @include flex(row, center, space-between, wrap);
    .itemsWrap {
      .logoDescription{
        width: 300px;
        height: 200px;
        padding: 30px;
      }
      padding: 33px 60px;
      .itemCall {
        a{
          display: flex;
          color: white;
          text-decoration: none;
          .phone{
            font-size: 1.3rem;
            font-weight: 300;
            line-height: 1.5334;
          }
          .iconPhone{
            margin-right: 5px;
            background: url("../../../common/icons/icons8-iphone.png");
            display: block;
            width: 20px;
            height: 24px;
            &:hover{
              background: url("../../../common/icons/icon-iphone-super.png");
            }
          }
          &:hover{
            color: #0eb0ee;
            border-bottom: solid 2px #0eb0ee;
          }
        }
        .tlg{
          padding-bottom: 10px;
          width: 30px;
        }

      }
        .itemMail{
          a{
            display: flex;
            color: white;
            text-decoration: none;
            .email{
              font-size: 1.3rem;
              font-weight: 300;
              line-height: 1.5334;
            }
            .iconEmail{
              margin-right: 5px;
              background: url("../../../common/icons/icon-mail.png");
              display: block;
              width: 23px;
              height: 24px;
              &:hover{
                background: url("../../../common/icons/icon-mail-super.png");
              }
            }
            &:hover{
              color: #0eb0ee;
              border-bottom: solid 2px #0eb0ee;
            }
          }
        }
        .itemAddress{
          a{
            display: flex;
            color: white;
            text-decoration: none;
            .address{
              font-size: 1.3rem;
              font-weight: 300;
              line-height: 1.5334;
            }
            .iconAddress{
              margin-right: 5px;
              background: url("../../../common/icons/icon-location.png");
              display: block;
              width: 23px;
              height: 24px;
              &:hover{
                background: url("../../../common/icons/icon-location-super.png");
              }
            }
            &:hover{
              color: #0eb0ee;
              border-bottom: solid 2px #0eb0ee;
            }
          }
        }
      .icons{
        padding: 20px;
        display: flex;
        justify-content: space-evenly;
        .whatsApp{
          width: 24px;
          height: 24px;
          display: block;
          background: url("../../../common/icons/icon-whatsapp.png");
          &:hover{
            background: url("../../../common/icons/icon-whatsapp-super.png");
          }
        }
        .telegram{
          width: 24px;
          height: 24px;
          display: block;
          background: url("../../../common/icons/icon-telegram.png");
          &:hover{
            background: url("../../../common/icons/icon-telegram-super.png");
          }
        }
        .call{
          width: 24px;
          height: 24px;
          display: block;
          background: url("../../../common/icons/icon-call.png");
          &:hover{
            background: url("../../../common/icons/icon-call-super.png");
          }
        }
      }
    }
  }
}



