
@mixin text($fonFamily, $fontSize,$fontWeight, $lineHeight){
  font-family: $fonFamily;
  font-size: $fontSize;
  font-weight:$fontWeight;
  line-height: $lineHeight;
}

@mixin container(){
  width: 80%;
  margin: 0 auto;
}

@mixin flex($direction, $justifyContent, $alignItems, $flexWrap){
  display: flex;
  flex-direction:$direction ;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-wrap: $flexWrap;
}