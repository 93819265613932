@import "../../../../common/styles/sass/variables.scss";
@import "../../../../common/styles/sass/mixins.scss";

.deliveryBlock{

}
.container{
    @include flex(column, space-evenly, center, wrap);
    @include container();
}

.iconBox{
    margin: 50px;
    border-radius: 100px;
    width: 100px ;
    height: 100px ;
    background-color: #0eb0ee;

}
.title{
    font-family:'Roboto', sans-serif;
    display: flex;
    align-items: center;
    color: $textColor;

}
.icon{
    width: 45px;
    padding: 25px;
}
.descriptionBlock{
    background-color: #8ae3ff;

}
.description{
    color: $secondColor;
}
.DeliveryNavBox{
    padding-bottom: 100px;

}
.active{
    text-decoration: none;
    @include flex(row, flex-start, center, wrap);

}
