@import "../../../common/styles/sass/variables.scss";
@import "../../../common/styles/sass/mixins.scss";

.deliveriesBlock{
    background-color: $primaryColor;


}
.container{
    @include container();
    @include flex(column, space-around, center, wrap);
}
.deliveries{
    @include flex(row, space-evenly, center, wrap);

}

.title{
    text-align: center;
    @include text($titleFontFamily,  2.2rem, 700, 1.0455  );
    color: #fff;
}