@import "../../common/styles/sass/variables.scss";
@import "../../common/styles/sass/mixins.scss";

.nav{
    @include flex(row, center, center, wrap);
    background-color:#30355d;
    a{
        cursor: pointer;
        text-transform: uppercase;
        padding: 12px 30px;
        border-left: solid 0.5px rgba(255, 255, 255, 0.1);
        border-right: solid 0.5px rgba(255, 255, 255, 0.1);
        font-family: 'Kanit', sans-serif;
        color: white;
        text-decoration: none;
        font-size: 0.9rem;
        font-weight: 700;
        line-height: 1.6429;
         &:hover{
           color:  #0eb0ee
        }
        .active{

        }

    }
}

