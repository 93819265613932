@font-face {
    font-family: JetBrainsMono ;
    src: url('../../../common/fonts/JetBrainsMono-Regular.ttf');
}
@font-face {
    font-family: Manrope ;
    src: url('../../../common/fonts/Manrope-Regular.ttf');
}

.AboutUsBlock{


background-color:#ffffff;
}
.container{
    margin: 0 auto;
    width: 80%;
}
.a{
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}
.textA{
    font-family: Manrope;
    display: block;
    color: #30355d ;
    font-size: 1.2rem;
    line-height: 1.2778;
    margin: 0 0 23px 0;
    font-weight: 500;
}
.textB{
    font-family: Manrope;
    display: block;
    color: #30355d ;
    font-size: 1.2rem;
    line-height: 1.2778;
    margin: 0 0 23px 0;
    font-weight: 500;
}
.itemsWrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 30px;


}

.team{
    display: inline-block;
    padding: 0 20px;
    margin-bottom: 20px;
    border: 0;
    font-size: 100%;
    line-height: 1.2;
    letter-spacing: 0.01em;
    color: #30355d;
}
.logistics{
    display: inline-block;
    padding: 0 20px;
    margin-bottom: 20px;
    border: 0;
    font-size: 100%;
    line-height: 1.2;
    letter-spacing: 0.01em;
    color: #30355d;
}
.offer{
    display: inline-block;
    padding: 0 20px;
    margin-bottom: 20px;
    border: 0;
    font-size: 100%;
    line-height: 1.2;
    letter-spacing: 0.01em;
    color: #30355d;
}
.delivery{
    display: inline-block;
    padding: 0 20px;
    margin-bottom: 20px;
    border: 0;
    font-size: 100%;
    line-height: 1.2;
    letter-spacing: 0.01em;
    color: #30355d;
}
.item{
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 auto;
    font-size: 16px;

    padding: 20px;

}
.centerImg{

}
.centerImg::before{
    top: auto;
    bottom: 100%;
    content: "";
    display: block;
    height: 30px;
    width: 2px;
    background: #0eb0ee;
    position: absolute;
    left: 17%;
    margin-left: 5px;
}
.centerImg::after{
    content: "";
    display: block;
    height: 30px;
    width: 2px;
    background: #0eb0ee;
    position: absolute;
    top: 100%;
    left: 17%;
    margin-left: 5px;
}
.iconTeam{
    padding-right: 10px;
    width: 64px;
    height: 64px;
}
.iconLogistic{
    padding-right: 10px;
    width: 64px;
    height: 64px;

}
.iconOffer{
padding-right: 10px;
    width: 64px;
    height: 64px;


}
.iconDelivery{
    padding-right: 10px;
    width: 64px;
    height: 64px;
}
.title{
    color: #30355d;

}