@import "../../common/styles/sass/variables";
@import "../../common/styles/sass/mixins";
@font-face {
  font-family: JetBrainsMono ;
  src: url('../../common/fonts/JetBrainsMono-Regular.ttf');
}
.header {
  background-color: $bgColor ;
  position: sticky;
  top: 0;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);

  .container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    .logo {
      cursor: pointer;

    }

    .location {
      align-items: center;
      display: flex;
      font-family: 'Roboto', sans-serif;
      color: $textColor;
      .iconLocation {
        padding: 5px;
        border: 1px solid $textColor;
        border-radius: 100%;
      }
        .l{
           text-decoration: none;
            padding: 5px;
            font-family: 'Roboto', sans-serif;
            color: $textColor;
        }
    }

    .number {
      align-items: center;
      display: flex;
      .iconCall {
          padding: 5px;
        border: 1px solid $textColor;
        border-radius: 100%;
      }
        .n{
            text-decoration: none;
            padding: 5px;
            font-family: 'Roboto', sans-serif;
            color: $textColor;
        }
    }
  }
  a{
    border: none;

    font-family: JetBrainsMono;
    font-weight: 700;
    line-height: 1.9167;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    background-color: #0eb0ee;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    border-radius: 40px;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: background-color 0.5s ease 0s;
    position: relative;
    top: 0;
    &:hover{
      background-color: #30355d;
    }
    &:active{
      top: 3px;
    }
    .active{

    }
  }
}


