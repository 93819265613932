@import "../../../../../common/styles/sass/variables.scss";
@import "../../../../../common/styles/sass/mixins.scss";




.oilProduct{
  width: 450px;
  padding-bottom: 30px;

}
.listBox{
  padding-bottom: 30px;
  padding-top: 20px;
  height: 540px;
  width: 260px;
  background-color: #30355d;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-bottom: solid 4px #0eb0ee;
  .btn{
    padding-top: 20px;
  }
}
.title{
  padding-top: 5px;
  color: #0eb0ee;
  border-bottom: solid 1px #0eb0ee;
}
.icon{
  background-color: #30355d;
  width: 50px;
  height: 50px;
}

.description{
  padding-left: 40px;
  text-align: left;
  @include text($titleFontFamily, 1rem, 700, 2.6);
  color: #aab9cd;
  margin-left: auto;
  margin-right: auto;
  width: 9em;
  p{
    line-height:1.5em;
  }
}
