.PartnersBlock{

}
.container{
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    width: 80%;
}
.innerWrap{
    background-color: white;

}