
.formWrapper{
 margin: 30px;
 background-color: #fff;
}
.formWrapper * {
 outline: none;
}
.form{
 padding: 33px 40px;
 border-top: solid 4px #0eb0ee;

}
.formBody{

}
.formTitle{
 text-align: center;
 color: #fff;

}
.formItem{
 margin: 0 0 20px 0 ;

}
.formLabel{
 font-size: 18px;
 display: block;
 margin: 0 0 10px 0;

}
.formInput{
background-color: #ebeced;
 outline: none;
 border: none;
 height: 50px;
 padding: 0 20px;
 border-radius: 5px;
 width: 50%;
 font-size: 18px;
 transition: all 0.5s ease 0s;
}
.formInput:focus{
 box-shadow: 0 0 15px #0eb0ee ;
}


textarea.formInput{
 min-height: 120px;
 resize: vertical;
 padding: 20px;
}
.formPhone{

}
.formEmail{

}
.checkbox{

 }
.checkboxInput{

 display: none;
}
.checkboxInput:checked + .checkboxLabel::after{
 transform: scale(1);
}
.checkboxLabel {
 font-size: 16px;
 line-height: 140%;
 display: inline-flex;
 align-items: center;
 position: relative;
 cursor: pointer;
}
.checkboxLabel::before{
 content: "";
 align-self: flex-start;
 flex: 0 0 24px;
 height: 24px;
 background-color: #fff;
 border-radius: 4px;
 margin: 0 10px 0 0;

}
.checkboxLabel::after{
 transition: transform 0.5s ease 0s;
 content: "";
 width: 16px;
 height: 16px;
 position: absolute;
 top: 4px;
 left: 4px;
 background-color: #0eb0ee;
 border-radius: 4px;
 transform: scale(0);


}
.checkboxLabel a{
 color: #0eb0ee;
 text-decoration: none;
}
.text{
 color: #30355d;
}
.formBtn{
  border: none;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  background-color: #0eb0ee;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: background-color 0.5s ease 0s;
  position: relative;
  top: 0;
}
.formBtn:hover{
 background-color: #30355d;
}
.formBtn:active{
 top: 3px;

}
.error{
color: #f00;
 font-size: 11px;
 font-weight: 400;
 line-height: 2.4546;
}

