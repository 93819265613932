@import "../../../common/styles/sass/variables.scss";
@import "../../../common/styles/sass/mixins.scss";


.oilProductsBlock{
background-color:#ebeced;
}

.container{
@include container();
@include flex(column, flex-start, center, wrap)
}
.oilProducts{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

